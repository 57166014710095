
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Receipts from "@/types/receipts";
import PO from "@/types/po";

import ReceiptsView from "./ReceiptsView.vue";
import ModalSpinner from "@/components/UI/ModalSpinner.vue";

export default defineComponent({
  components: {
    ReceiptsView,
    ModalSpinner,
  },
  async created() {
    await this.fetchFdicts("RECEIPTS");
  },
  computed: {
    ...mapGetters({
      getActiveReceipts: "inventoryModule/getActiveReceipts",
    }),
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
      createReceiptsById: "inventoryModule/createReceiptsById",
      updateReceiptsById: "inventoryModule/updateReceiptsById",
      createReceiptsFromPurchaseOrder:
        "inventoryModule/createReceiptsFromPurchaseOrder",
      postReceiptsById: "inventoryModule/postReceiptsById",
      fetchFdicts: "fdict/fetchFdict",
    }),
    async handleSaveReceipts(receipts: any) {
      this.loading = true;
      if (this.getActiveReceipts.record.receipts_id) {
        await this.handleUpdateReceipts(receipts);
      } else {
        await this.handleCreateReceipts(receipts);
      }
      this.loading = false;
    },
    async handleCreateReceipts(receipts: Receipts) {
      const response = await this.createReceiptsById({
        receipts: receipts,
        id: this.getActiveReceipts.id,
      });

      const { recordId } = response;

      if (recordId) {
        this.addNotification({
          type: "success",
          message: `Receipts #${recordId} created successfully`,
        });
      }
    },
    async handleUpdateReceipts(receipts: Receipts) {
      const response = await this.postReceiptsById({
        receipts: receipts,
        id: this.getActiveReceipts.id,
      });

      const { status } = response;

      if (status === "success") {
        this.addNotification({
          type: "success",
          message: `Receipts #${receipts.receipts_id} updated successfully`,
        });
      }
    },
    async handleSetPurchaseOrder(po: any) {
      const id = this.getActiveReceipts.id;
      if (this.getActiveReceipts.record.receipts_id) {
        await this.updateReceiptsById({
          id: this.getActiveReceipts.id,
          receipts: this.getActiveReceipts.record,
          po: po,
        });
        return;
      } else {
        await this.createReceiptsFromPurchaseOrder({
          po: po,
          id: id,
        });
      }
    },
    handleSetReceipts({ receipts, po }: { receipts: Receipts; po: PO }) {
      this.updateReceiptsById({
        id: this.getActiveReceipts.id,
        receipts: receipts,
        po: po ?? this.getActiveReceipts.po,
      });
    },
  },
});
