
import { defineComponent } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import Tooltip from "primevue/tooltip";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Textarea from "primevue/textarea";
import Card from "primevue/card";
import AutoComplete from "primevue/autocomplete";
import Receipts, { LineNumberItem } from "../../types/receipts";
import Dropdown from "primevue/dropdown";
import CollapsibleSection from "@/components/UI/CollapsibleSection.vue";
import { mapActions, mapGetters } from "vuex";
import PO from "../../types/po";

import cloneDeep from "lodash/cloneDeep";

import ReceiptsSerivce from "../../services/ReceiptsService";
import PoService from "../../services/PoService";
const receiptsService = new ReceiptsSerivce();
const poService = new PoService();

export default defineComponent({
  components: {
    DataTable,
    Column,
    InputText,
    Calendar,
    Button,
    Dialog,
    Card,
    AutoComplete,
    Textarea,
    Dropdown,
    CollapsibleSection,
  },
  emits: ["saveReceipts", "setReceipts", "setPurchaseOrder"],
  directives: {
    tooltip: Tooltip,
  },
  props: {
    receipts: {
      type: Object as () => Receipts,
      default: () => new Receipts(),
    },
    po: {
      type: Object as () => PO,
    },
  },
  created() {
    this.record = cloneDeep(this.receipts);
    if (this.lineItems) {
      this.lineItems.forEach((item) => {
        if (!item.receipt_qty_items) {
          item.receipt_qty_items = [
            {
              receipt_qty: "0.00",
              bin_number: "",
              it_id: "",
              lot_no: "",
              vendor_lot_no: "",
            },
          ];
        }
      });
    }
  },
  mounted() {
    this.selectedItems = this.lineItems.filter(
      (item) => this.getTotalOpenQty(item) === this.totalQuantityReceived(item),
    );

    if (!this.po?.po_id) {
      (this.$refs.receiptsAutoComplete as any).$el.firstChild.focus();
    }
  },
  computed: {
    ...mapGetters({
      getField: "fdict/getField",
      correlativeExists: "fdict/correlativeExists",
    }),
    hasFileBin(): boolean {
      return this.correlativeExists("PO", "FILE.BIN");
    },
    readOnly(): boolean {
      return this.record.status === "P";
    },
    statusOptions(): any {
      return this.getField("RECEIPTS", "24").valid_value_items;
    },
    lineItems(): LineNumberItem[] {
      if (this.record.receipts_id) {
        return this.record.li_no_items.filter((item) => item.li_no);
      } else {
        return this.record.li_no_items.filter(
          (item) => this.getTotalLineItemSchedule(item) > 0,
        );
      }
    },
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
    }),
    rowStyle(lineNumber: LineNumberItem): any {
      const totalOpenQty = parseFloat(this.getTotalOpenQty(lineNumber));
      const totalReceived = parseFloat(this.totalQuantityReceived(lineNumber));

      if (totalReceived === 0) {
        return {};
      }

      if (totalOpenQty > totalReceived) {
        return { backgroundColor: "#F8DFA3" };
      }

      if (totalOpenQty < totalReceived) {
        return { backgroundColor: "#FFB2B2" };
      }
      return {};
    },
    handleRowSelect(event: any) {
      let quantity = 0;

      const lineItem = this.lineItems.find(
        (item) => item.li_no === event.data.li_no,
      );

      if (!lineItem || !lineItem.schedule_items) {
        return;
      }

      lineItem.schedule_items.forEach((scheduleItem) => {
        quantity += parseFloat(scheduleItem.quantity || "0");
      });
      lineItem.receipt_qty_items = [];
      lineItem.receipt_qty_items.push({
        receipt_qty: quantity.toFixed(2),
        bin_number: "",
        it_id: "",
        lot_no: "",
        vendor_lot_no: "",
      });

      lineItem.li_qty = quantity.toFixed(2);

      this.$emit("setReceipts", { receipts: this.record });
    },
    handleRowUnSelect(event: any) {
      const lineItem = this.lineItems.find(
        (item) => item.li_no === event.data.li_no,
      );

      if (lineItem) {
        lineItem.receipt_qty_items = [
          {
            receipt_qty: "0.00",
            bin_number: "",
            it_id: "",
            lot_no: "",
            vendor_lot_no: "",
          },
        ];
        lineItem.li_qty = "";
      }

      this.$emit("setReceipts", { receipts: this.record });
    },
    getTotalScheduleQty(scheduleItem: LineNumberItem): string {
      const id = scheduleItem.li_no;

      const poLineItem =
        this.po && this.po.li_no_items.find((item) => item.li_no === id);

      if (poLineItem && poLineItem.tot_sched_qty) {
        return poLineItem.tot_sched_qty;
      }

      return "0.00";
    },
    getTotalOpenQty(scheduleItem: LineNumberItem): string {
      const id = scheduleItem.li_no;
      const poLineItem =
        this.po && this.po?.li_no_items.find((item) => item.li_no === id);

      if (poLineItem && poLineItem.tot_open_qty) {
        return poLineItem.tot_open_qty;
      }

      return "0.00";
    },
    getPriorReceivedQty(scheduleItem: LineNumberItem): string {
      const id = scheduleItem.li_no;
      const poLineItem =
        this.po && this.po.li_no_items.find((item) => item.li_no === id);

      if (poLineItem && poLineItem.total_rec) {
        return poLineItem.total_rec;
      }

      return "0.00";
    },
    getPOLiNotes(scheduleItem: LineNumberItem): string {
      const id = scheduleItem.li_no;
      const poLineItem =
        this.po && this.po.li_no_items.find((item) => item.li_no === id);

      if (poLineItem && poLineItem.li_notes) {
        return poLineItem.li_notes;
      }

      return "";
    },
    getTotalLineItemSchedule(lineNumber: LineNumberItem): number {
      let totalQuantity = 0;
      lineNumber.schedule_items?.forEach((item) => {
        totalQuantity += parseFloat(item.quantity || "0");
      });
      return totalQuantity;
    },
    totalQuantityReceived(lineNumber: LineNumberItem): string {
      let totalQuantity = 0;
      lineNumber.receipt_qty_items?.forEach((item) => {
        totalQuantity += parseFloat(item.receipt_qty || "0");
      });
      return totalQuantity.toFixed(2);
    },
    getFileBin(lineNumber: LineNumberItem): string {
      const id = lineNumber.li_no;
      const poLineItem =
        this.po && this.po.li_no_items.find((item) => item.li_no === id);

      if (poLineItem && poLineItem.file_bin) {
        return poLineItem.file_bin;
      }

      return "";
    },
    handleReceiveAll() {
      this.lineItems.forEach((item) => {
        let quantity = 0;
        if (item.schedule_items) {
          item.schedule_items.forEach((scheduleItem) => {
            quantity += parseFloat(scheduleItem.quantity || "0");
          });
        } else {
          const po = this.po?.li_no_items.find(
            (poItem) => poItem.li_no === item.li_no,
          );
          if (po) {
            quantity = parseFloat(po.tot_open_qty || "0");
          }
        }
        item.receipt_qty_items = [];
        item.receipt_qty_items.push({
          receipt_qty: quantity.toFixed(2),
          bin_number: "",
          it_id: "",
          lot_no: "",
          vendor_lot_no: "",
        });
        item.li_qty = quantity.toFixed(2);
      });

      this.selectedItems = this.lineItems;

      this.$emit("setReceipts", { receipts: this.record });
    },
    partDescription(lineItem: LineNumberItem): string {
      return lineItem?.desc_items
        ?.map((descItem) => {
          return descItem.desc;
        })
        .join("\n");
    },
    validateQuantity(data: any) {
      console.log(data);
    },
    handleEnter(event: any) {
      event.enterKeyPressed = true;
      this.loading = false;
    },
    async searchReceipts(event: any) {
      try {
        this.loading = true;
        const resp = await receiptsService.searchReceipts({ id: event.query });

        if (!resp.receipts_items) {
          return;
        }

        this.receipts_items = resp.receipts_items;

        if (this.receipts_items.length === 0) {
          throw new Error("No receipts found");
        } else if (this.receipts_items.length === 1) {
          const receipt = this.receipts_items[0];
          const poNumber = receipt.receipts_id.split("-")[0];
          const poResponse = await poService.posearch({
            selection: poNumber,
            correls: "vendor_name open_amount order_amount file_bin",
          });
          this.$emit("setReceipts", {
            receipts: receipt,
            po: poResponse.po_items[0],
          });
          this.$router.push(`/inventory/receipts/${this.record.receipts_id}`);
        } else {
          this.searchOptions = resp.receipts_items;
        }
      } catch (error) {
        this.addNotification({
          type: "error",
          message: error,
        });
      } finally {
        this.loading = false;
      }
    },
    async searchPurchaseOrders(event: any) {
      try {
        this.loading = true;
        const resp = await poService.posearch({
          selection: event.query,
          correls: "vendor_name open_amount order_amount file_bin",
        });
        this.poOptions = resp.po_items;

        if (this.poOptions.length === 0) {
          throw new Error("No POs found");
        } else if (this.poOptions.length === 1) {
          this.$emit("setPurchaseOrder", this.poOptions[0]);
        } else {
          this.searchOptions = resp.receipts_items;
        }
      } catch (error) {
        this.addNotification({
          type: "error",
          message: error,
        });
      } finally {
        this.loading = false;
      }
    },
    handlePurchaseOrdersEnter(event: any) {
      event.enterKeyPressed = true;
      this.loading = false;
    },
    handlePurchaseOrders(event: any) {
      const purchaseOrder = this.poOptions.find(
        (item: any) => item.po_id === event.value.po_id,
      );

      if (purchaseOrder) {
        this.$emit("setPurchaseOrder", purchaseOrder);
      }

      this.loading = false;
    },
    handleClearAll() {
      this.lineItems.forEach((item) => {
        item.li_qty = "";
        item.receipt_qty_items = [
          {
            receipt_qty: "",
            bin_number: "",
            it_id: "",
            lot_no: "",
            vendor_lot_no: "",
          },
        ];
      });

      this.selectedItems = [];

      this.$emit("setReceipts", { receipts: this.record });
    },
    updateReceivedQuantityById(id: number) {
      const item = this.record.li_no_items.find(
        (item: any) => item.li_no === id,
      );

      if (!item) {
        return;
      }

      let totalQty = 0;
      item.receipt_qty_items.forEach((receipt: any) => {
        totalQty += parseFloat(receipt.receipt_qty || "0");
      });
      item.li_qty = totalQty.toFixed(2);

      this.$emit("setReceipts", { receipts: this.record });
    },
    handleReceiptQtyNewRow(data: any, index: number) {
      if (data.length === index + 1) {
        data.push({
          receipt_qty: "0.00",
          bin_number: "",
          it_id: "",
          lot_no: "",
          vendor_lot_no: "",
        });
      }
    },
    handleReceiptDeleteRow(data: any, index: number) {
      if (data.length > 1) {
        data.splice(index, 1);
      }
    },
    handleSave() {
      let hasError = false;

      this.record.li_no_items.forEach((item) => {
        this.po?.li_no_items.forEach((poItem) => {
          if (item.li_no === poItem.li_no) {
            const totalReceivedQty = item.receipt_qty_items.reduce(
              (total, receiptItem) => {
                return total + parseFloat(receiptItem.receipt_qty || "0");
              },
              0,
            );

            if (totalReceivedQty > parseFloat(poItem.tot_open_qty)) {
              this.addNotification({
                type: "error",
                message: `Quantity received (${totalReceivedQty}) cannot be greater than total open quantity (${poItem.tot_open_qty}) for line item ${item.li_no}`,
              });
              hasError = true;
            }
          }
        });
      });
      if (!hasError) {
        this.$emit("saveReceipts", this.record);
      }
    },
    onViewDetailsClick(event: any) {
      this.showScheduleDialog = true;
      this.selectedOrderSchedule = event.schedule_items;
      this.rev = event.rev;
      this.buyUm = event.buy_um;
      this.buyFactor = event.buy_factor;
      this.woid = event.wo_id;
    },
    async handleReceipts(event: any) {
      const receipt = this.receipts_items.find(
        (item: any) => item.receipts_id === event.value.receipts_id,
      );

      if (receipt) {
        const receipt = this.receipts_items[0];
        const poNumber = receipt.receipts_id.split("-")[0];
        const poResponse = await poService.posearch({
          selection: poNumber,
          correls: "vendor_name open_amount order_amount file_bin",
        });
        this.$emit("setReceipts", {
          receipts: receipt,
          po: poResponse.po_items[0],
        });

        this.$router.push(`/inventory/receipts/${this.record.receipts_id}`);
      }

      this.loading = false;
    },
  },
  watch: {
    receipts: {
      handler: function (newVal, oldVal) {
        this.record = cloneDeep(newVal);
        this.selectedItems = this.lineItems.filter(
          (item) =>
            this.getTotalOpenQty(item) === this.totalQuantityReceived(item),
        );
      },
      deep: true,
    },
  },
  data() {
    return {
      record: this.receipts,
      expandedRows: [],
      expandedPhantomRows: [],
      showScheduleDialog: false,
      selectedOrderSchedule: {} as any,
      stockLocation: "",
      receiptLocation: "",
      rev: "",
      buyUm: "",
      buyFactor: "",
      fileBin: "",
      woid: "",
      po_li_notes: "",
      searchOptions: [],
      poOptions: [],
      receipts_items: [] as Receipts[],
      loading: false,
      selectedItems: [] as LineNumberItem[],
      searchPoNumber: "",
      phantomOpen: false,
    };
  },
});
