import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReceiptsView = _resolveComponent("ReceiptsView")!
  const _component_ModalSpinner = _resolveComponent("ModalSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.getActiveReceipts)
      ? (_openBlock(), _createBlock(_component_ReceiptsView, {
          key: 0,
          receipts: _ctx.getActiveReceipts.record,
          po: _ctx.getActiveReceipts.po,
          onSetReceipts: _ctx.handleSetReceipts,
          onSaveReceipts: _ctx.handleSaveReceipts,
          onSetPurchaseOrder: _ctx.handleSetPurchaseOrder
        }, null, 8, ["receipts", "po", "onSetReceipts", "onSaveReceipts", "onSetPurchaseOrder"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, "Issue with loading receipts")),
    _createVNode(_component_ModalSpinner, { visible: _ctx.loading }, null, 8, ["visible"])
  ], 64))
}