
import { defineComponent } from "vue";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";

export default defineComponent({
  components: {
    Dialog,
    ProgressSpinner,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
});
